import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getUser, newOrder } from "../api";
import arrowIcon from "../img/arrow-left.svg"

const Contact = () => {
  const nav = useNavigate();
  
  return (
    <>
      <div class="container-order-data">
        <div class="order-form">
            
          <p class="order-data-page-title"> <img src={arrowIcon} onClick={() => {
                nav("/")
            }} />Контакты</p>

            <p>По коммерческим вопросам обращаться по телефону:
              {' '}
              <a onClick={() => {
                window.open("tel:+79250685113", "_blank")
              }}>
              +79250685113
              </a>
            </p>
            <p>Номер технической поддержки:
              {' '}
              <a onClick={() => {
                window.open("tel:+79786121068", "_blank")
              }}>
                +79786121068
              </a>
            </p>
        </div>
      </div>
    </>
  );
};

export default Contact;
