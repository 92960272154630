
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getUser, newOrder } from "../api";
import arrowIcon from "../img/arrow-left.svg"

const Terms = () => {
  const nav = useNavigate();
  
  return (
    <>
      <div class="container-order-data">
        <div class="order-form">
            
          <p class="order-data-page-title"> <img src={arrowIcon} onClick={() => {
                nav("/")
            }} />Условия работы</p>

          <p>График работы: 06:00 - 19:00
          <br /><br />
          Склад: 07:00 - 20:00</p>
        </div>
      </div>
    </>
  );
};

export default Terms;
