import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import Catalog from "./views/Catalog";
import Cart from "./views/Cart";
import Order from "./views/Order";
import Product from "./views/Product";
import Category from "./views/Category";
import Contact from "./views/Contact";
import Terms from "./views/Terms";
import Orders from "./views/Orders";
import OrderInfo from "./views/OrderInfo";
import Search from "./views/Search";
import { getUser } from "./api";

const App = () => {
  useEffect(() => {
    async function checkData() {
      const u = await getUser();

      if (!u || u?.error) {
        window.Telegram.WebApp.close();
      }
    }

    checkData();
  }, []);
  return (
    <BrowserRouter>
      <Toaster />
      <Routes>
        <Route path="/" element={<Catalog />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/order" element={<Order />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/category/:id" element={<Category />} />
        <Route path="/orderInfo/:id" element={<OrderInfo />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/search" element={<Search />} />
        <Route
          path="/hook/payment/success"
          element={<Catalog paymentStatus={"success"} />}
        />
        <Route
          path="/hook/payment/fail"
          element={<Catalog paymentStatus={"error"} />}
        />
        <Route path="*" element={<Catalog />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
