import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getUser, newOrder } from "../api";
import arrowIcon from "../img/arrow-left.svg";
import arrow2Icon from "../img/arrow-right.svg";

function formatNumberWithSpaces(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}


const Orders = () => {
  const nav = useNavigate();

  const [user, setUser] = useState({
    orders: [],
  });

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const _user = JSON.parse(localStorage.getItem("user"));
      setUser(_user);
      console.log(_user);
    }
  }, []);

  return (
    <>
      <div class="container-order">
        <div
          class="page-title-order"
          style={{
            justifyContent: "left",
          }}
        >
          <img
            src={arrowIcon}
            style={{
              marginRight: 12,
            }}
            onClick={() => {
              nav("/");
            }}
          />
          <span>История заказов</span>
        </div>

        <div class="cart">
          {user.orders.map((order) => (
            <div
              class="cart-item-row"
              style={{ borderBottom: "2px solid #1c1c1c" }}
              onClick={() => {
                localStorage.setItem(
                  "order",
                  JSON.stringify(order)
                );
                nav(`/orderInfo/${order.orderId}`);
              }}
            >
              <div
                class="cart-item-data"
                style={{
                  alignItems: "center",
                }}
              >
                <div class="cart-item-label">
                  Заказ #{order.orderId}
                  <div class="cart-item-caption">
                    Дата заказа:{" "}
                    {new Date(order.orderDate * 1000).toLocaleString()}
                    <br />
                    Кол-во товаров: {order.products.length}
                  </div>
                  <span>{formatNumberWithSpaces(parseInt(order.total))} ₽</span>
                </div>
                <img src={arrow2Icon} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Orders;
