const getProducts = async () => {
  const req = await fetch("https://shop-api.toyseller.site/api/products", {});
  const res = await req.json();

  return res.data;
};
const getUser = async () => {
  const req = await fetch("https://shop-api.toyseller.site/api/user/get", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "MiniApp",
    },
    body: JSON.stringify({
      tgUserData: window.Telegram.WebApp.initDataUnsafe,
    }),
  });
  const res = await req.json();

  return res.data;
};

const newOrder = async (data) => {
  const req = await fetch("https://shop-api.toyseller.site/api/order", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "MiniApp",
    },
    body: JSON.stringify({
      ...data,
      // userId: window.Telegram.WebApp.initDataUnsafe.user.id,
      tgUserData: window.Telegram.WebApp.initDataUnsafe,
    }),
  });
  const res = await req.json();

  return res;
};

const payTBank = async (orderID) => {
  const req = await fetch(
    `https://shop-api.toyseller.site/api/payment/tbank/init/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "MiniApp",
      },
      body: JSON.stringify({
        orderID: orderID,
        tgUserData: window.Telegram.WebApp.initDataUnsafe,
      }),
    }
  );
  const res = await req.json();

  return res;
};

export { getUser, getProducts, newOrder, payTBank };
